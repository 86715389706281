<!--
 * @Author: Dyf
 * @LastEditors: wang hai yang
 * @Date: 2023-03-06 15:42:41
 * @LastEditTime: 2023-05-19 17:50:16
 * @Descripttion: 教师详情
-->
<style lang="scss" scoped>
.teacher-detail {
    @include innerPage(40px 32px 48px 15px);
    @include pageTitle(0);

    .detail-wrapper {
        width: calc(100% - 15px);
        height: calc(100% - 74px);
        margin-top: 36px;
        overflow: hidden;
        overflow-y: auto;
        box-sizing: border-box;
        padding-right: 58px;

        .info {

            &--base,
            &--preparation {
                width: 100%;
                box-sizing: border-box;
                padding-left: 12px;
            }

            &--base {
                margin-bottom: 28px;

                .el-form {
                    width: 908px;
                    justify-content: space-between;
                    align-items: flex-start;
                }

                .el-form-item {
                    width: 444px;
                    margin-bottom: 20px;
                    align-items: flex-start;

                    p {
                        min-height: 46px;
                        height: auto;
                        margin-top: 12px;
                        line-height: 28px;
                        padding: 9px 28px;

                        &:first-child {
                            margin-top: 0;
                        }
                    }
                }
            }

            &--preparation {
                width: 89.63%;
                min-width: 800px;
                margin-top: 34px;
                box-sizing: border-box;
                padding-left: 14px;

                .statistical-group {
                    width: 100%;
                    height: 274px;
                    @include flexBox(space-between);

                    li {
                        width: calc((100% - 60px) / 3);
                        height: 100%;
                        border-radius: 10px;
                        background: #f0f2f7;
                        box-sizing: border-box;
                        padding: 24px 28px;

                        h5 {
                            line-height: 36px;
                            color: #282828;
                        }

                        .progress {
                            width: 160px;
                            height: 160px;
                            position: relative;
                            margin: 20px auto 0;

                            .center-text {
                                width: 100%;
                                height: 100%;
                                position: absolute;
                                left: 0;
                                top: 0;
                                flex-direction: column;
                                @include flexBox(center);

                                .percentage {
                                    font-size: 30px;
                                    color: #6c4ecb;
                                    line-height: 1em;

                                    span {
                                        font-size: 14px;
                                    }

                                    &.success {
                                        color: #2ac293;
                                    }

                                    &.danger {
                                        color: #f66478;
                                    }
                                }
                            }
                        }
                    }
                }

                .class-table {
                    margin-top: 40px;

                    ::v-deep {

                        th,
                        th .cell {
                            height: 72px;
                            line-height: 72px;
                        }

                        th {
                            background: #dee1ef;

                            &:first-child {
                                border-radius: 15px 0 0 15px;
                            }

                            &:nth-last-child(2) {
                                border-radius: 0 15px 15px 0;

                                .cell::after {
                                    background-color: transparent;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>

<template>
    <section class="teacher-detail">
        <div class="page-title">
            <h3>基本信息</h3>
            <el-button type="custom_primary" size="small" @click="$router.go(-1)">
                返回
            </el-button>
        </div>
        <div class="detail-wrapper">
            <div class="info--base">
                <el-form class="readonly" label-width="82px" ref="baseInfo" inline :model="baseInfo">
                    <el-form-item label="教师姓名">
                        <p>{{ baseInfo.teuse_name }}</p>
                    </el-form-item>
                    <el-form-item label="账号">
                        <p>{{ baseInfo.teuse_account }}</p>
                    </el-form-item>
                    <el-form-item label="省/市/区">
                        <p>{{ baseInfo.teuse_province_city_area }}</p>
                    </el-form-item>
                    <el-form-item label="教师评级">
                        <p>{{ baseInfo.teuse_lev_title }}</p>
                    </el-form-item>
                    <el-form-item class="class-distribution" label="授课班级" v-if="!$isEmpty(baseInfo.classData)">
                        <p v-for="item in baseInfo.classData" :key="item.teuse_ide_id">{{ item.teacher_identitys }}</p>
                    </el-form-item>
                </el-form>
            </div>
            <div class="page-title">
                <h3>本学期备课情况</h3>
            </div>
            <div class="info--preparation">
                <ul class="statistical-group">
                    <li>
                        <h5 class="bold">备课率</h5>
                        <div class="progress">
                            <el-progress class="percentage-progress  success" :width="160" :stroke-width="20" type="circle"
                                stroke-linecap="butt" :percentage="teacherDetail.sum_lesson_rate" :show-text="false" />
                            <div class="center-text">
                                <p class="pf_bold percentage success">{{ teacherDetail.sum_lesson_rate }}<span
                                        class="pf">%</span></p>
                            </div>
                        </div>
                    </li>
                    <li>
                        <h5 class="bold">备课完整率</h5>
                        <div class="progress">
                            <el-progress class="percentage-progress" :width="160" :stroke-width="20" type="circle"
                                stroke-linecap="butt" :percentage="teacherDetail.sum_lesson_complete_rate"
                                :show-text="false" />
                            <div class="center-text">
                                <p class="pf_bold percentage">{{ teacherDetail.sum_lesson_complete_rate }}<span
                                        class="pf">%</span></p>
                            </div>
                        </div>
                    </li>
                    <li>
                        <h5 class="bold">学生考试合格率</h5>
                        <div class="progress">
                            <el-progress class="percentage-progress danger" :width="160" :stroke-width="20" type="circle"
                                stroke-linecap="butt" :percentage="teacherDetail.sum_examination_rate" :show-text="false" />
                            <div class="center-text">
                                <p class="pf_bold percentage danger">{{ teacherDetail.sum_examination_rate }}<span
                                        class="pf">%</span></p>
                            </div>
                        </div>
                    </li>
                </ul>
                <div class="table-box class-table">
                    <el-table :data="teacherDetail.tui_data">
                        <el-table-column align="center" prop="grade_scclaname" label="授课班级" min-width="19.05%" />
                        <el-table-column align="center" prop="sysub_name" label="科目" min-width="19.12%" />
                        <el-table-column align="center" prop="lesson_rate" label="备课率" min-width="17.76%">
                            <template slot-scope="scope">
                                <p>{{ scope.row.lesson_rate + "%" }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" prop="lesson_complete_rate" label="备课完整率" min-width="20.73%">
                            <template slot-scope="scope">
                                <p>{{ scope.row.lesson_complete_rate + "%" }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" prop="examination_rate" label="学生考试合格率" min-width="23.3%">
                            <template slot-scope="scope">
                                <p>{{ scope.row.examination_rate + "%" }}</p>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { teauserInfo } from "@api/user";

export default {
    name: 'user_teacherDetail',
    data() {
        return {
            teacherDetail: {},
            baseInfo: {},
            tableData: [
                {
                    id: 1,
                    class: '四年级1班',
                    subject: '数学',
                    preparationRate: '80%',
                    integrityRate: '100%',
                    passRate: '85%',
                }, {
                    id: 2,
                    class: '四年级2班',
                    subject: '数学',
                    preparationRate: '80%',
                    integrityRate: '100%',
                    passRate: '85%',
                },
            ],
        }
    },
    created() {
        this.init();
    },
    methods: {
        /** 初始化列表数据 */
        async init() {
            const res = await teauserInfo({ teuser_id: this.$route.params.id });
            if (res.status == 200) {
                this.baseInfo = res.data;
                this.teacherDetail = res.data.preparation_data;
            }
        },
    }
}
</script>